import Pubnub from "pubnub";
import { UserBasic } from "./validation/auth-validation";

export function initPubnub(user: UserBasic) {
	return new Pubnub({
		publishKey: user.publishKey,
		subscribeKey: user.subscribeKey,
		uuid: user.uuid,
		// heartbeatInterval: 1000,
	});
}
